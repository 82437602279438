import router from "@/router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "./ApiService";

export enum LoginType {
  Otp = 1,
  Password = 2,
}

export default class AccountService {
  public static createOtp(values) {
    return ApiService.postToken("/CreateOtp", values);
  }

  public static resetPassword(values: {
    email: string;
    newPassword: string;
    repeatNewPassword: string;
    otp: string;
  }) {
    return ApiService.post({
      resource: "/ResetUserPassword",
      params: values,
      showMessage: true,
    });
  }

  public static createToken(
    phoneOrEmail: string,
    password: string,
    googleAuthCode: string,
    loginType: LoginType
  ) {
    const params = new URLSearchParams();
    params.append("client_id", process.env.VUE_APP_CLIENT_ID ?? "");
    params.append("userName", phoneOrEmail);
    params.append("googleAuthCode", googleAuthCode);
    loginType == LoginType.Otp
      ? params.append("otp", password)
      : params.append("password", password);
    params.append("grant_type", "password");
    return ApiService.postToken("/GetAgencyToken", params).then(async (p) => {
      await store.dispatch(Actions.LOGIN, p.data.access_token);
    });
  }
  public static getUserInfo() {
    AccountService.getCurrentUser().then((res) => {
      store.commit(Mutations.SET_USER, res.data.data);
    });
  }
  public static logout() {
    store.dispatch(Actions.LOGOUT);
    router.push({ name: "sign-in" });
  }

  static getCurrentUser() {
    return ApiService.get({
      resource: "/CurrentUser",
    });
  }

  static updateCurrentUserProfilePicture(fileUploadResponse: any) {
    return ApiService.post({
      resource: "/UpdateCurrentUserProfilePicture",
      params: {
        profilePictureFileName: fileUploadResponse.data.data.path,
      },
      showMessage: true,
    });
  }

  static changePassword(params) {
    return ApiService.post({
      resource: "/ChangePassword",
      params,
      showMessage: true,
    });
  }

  static updateCurrentUserProfile(params) {
    return ApiService.post({
      resource: "/UpdateCurrentUserProfile",
      params,
      showMessage: true,
    });
  }

  static changePhoneNumber(params) {
    return ApiService.post({
      resource: "/AccountChangePhone",
      params,
      showMessage: true,
    });
  }

  static getUserByPhoneOrEmail(emailOrPhone) {
    return ApiService.get({
      resource: "/GetUserByUserName",
      params: { emailOrPhone },
      rejectError: true,
    });
  }

  static async getAllRoles(params) {
    return ApiService.post({
      resource: "/GetAllRole",
      params: params,
    });
  }
  static async getAllPermissions(params) {
    return ApiService.post({
      resource: "/GetAllPermission",
      params: params,
    });
  }
}
