import { computed, ref } from "vue";

/**
 * locale for calendar
 * @returns {string}
 */
export const calendarLocale = computed(() => {
  return process.env.VUE_APP_CALENDAR_LOCALE;
});

/**
 * currencies
 * @returns {string}
 */
export const currencies = computed(() => {
  return process.env.VUE_APP_CURRENCIES;
});

/**
 * gregorain date
 * @returns {boolean}
 */
export const GregorainDateRequired = computed(() => {
  const getGregorainDatePolicy = JSON.parse(
    process.env.VUE_APP_GREGORIAN_DATE_POLICY
  );
  return getGregorainDatePolicy.GREGORIAN_DATE_REQUIRED_FOR_EXPIRY_AND_BIRTH_DATE;
});

/**
 * currencies rtl
 * @returns {boolean}
 */
export const CurrenciesRtlRequired = computed(() => {
  const getCurrenciesRtlPolicy = JSON.parse(
    process.env.VUE_APP_CURRENCIES_RTL_POLICY
  );
  return getCurrenciesRtlPolicy.CURRENCIES_RTL_POLICY;
});

/**
 * passport number
 * @returns {boolean}
 */
export const PassportNumberRequired = computed(() => {
  const getPassportNumberPolicy = JSON.parse(
    process.env.VUE_APP_PASSPORT_NUMBER_POLICY
  );
  return getPassportNumberPolicy.PASSPORT_NUMBER_POLICY;
});

/**
 * passport number
 * @returns {boolean}
 */
export const isMobileScreen = computed(() => {
  const isMobile = ref<boolean>(false);
  const mql = window.matchMedia("(max-width: 768px)");
  isMobile.value = mql.matches;
  function handleMqlChange(e) {
    isMobile.value = e.matches;
  }
  mql.addEventListener("change", handleMqlChange);
  return isMobile.value;
});
