import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import SwalService from "./SwalService";
import { messages } from "../config/messages-fa";
import router from "@/router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { ElNotification } from "element-plus";
import i18n from "@/core/plugins/i18n";
import utils, { NotifType } from "@/core/helpers/utils";
const { t } = i18n.global;
/**
 * @description service to call HTTP request via Axios
 */

class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static baseUrl = "";

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_API_NEW_BASE_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    const token = JwtService.getToken();
    if (token && token !== "undefined") {
      ApiService.vueInstance.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    }
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params,
    blockUi = true,
    showMessageSuccess = false,
    messageTextSuccess = "",
    showMessageError = true,
    rejectError = false
  ): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    const config = { data: params } as AxiosRequestConfig;
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .get(`${resource}`, config)
        .then((p) => {
          if (showMessageSuccess) {
            SwalService.success(messageTextSuccess);
          }
          resolve(p);
        })
        .catch((er) => {
          if (showMessageError) {
            this.errorHandler(er);
          }
          if (rejectError) {
            reject(er);
          }
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static getSlug(
    resource: string,
    slug = "" as string,
    blockUi = true,
    showMessageSuccess = false,
    messageTextSuccess = "",
    showMessageError = true,
    rejectError = false,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .get(`${resource}/${slug}`, config)
        .then((p) => {
          if (showMessageSuccess) {
            SwalService.success(messageTextSuccess);
          }
          resolve(p);
        })
        .catch((er) => {
          if (showMessageError) {
            this.errorHandler(er);
          }
          if (rejectError) {
            reject(er);
          }
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get({
    resource,
    params,
    showMessage = false,
    showAlert = false,
    showMessageError = true,
    messageText = "",
    blockUi = false,
    rejectError = false,
    config = {},
  }: {
    resource: string;
    params?: any;
    showMessage?: boolean;
    showAlert?: boolean;
    showMessageError?: boolean;
    messageText?: string;
    blockUi?: boolean;
    rejectError?: boolean;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    config = { ...config, params };
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .get(`${resource}`, config)
        .then((p) => {
          if (showAlert) {
            SwalService.success(messageText);
          } else if (showMessage) {
            utils.showMessageToast(NotifType.success, messageText);
          }
          resolve(p);
        })
        .catch((er) => {
          if (showMessageError) {
            this.errorHandler(er);
            if (showAlert) {
              SwalService.errorAlert();
            } else if (showMessage) {
              utils.showMessageToast(
                NotifType.error,
                t("operation_encountered_error")
              );
            }
            if (rejectError) {
              reject(er);
            }
          }
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post({
    resource,
    params,
    showMessage = false,
    showAlert = false,
    showMessageError = true,
    messageText = "",
    blockUi = false,
    rejectError = false,
    config = {},
  }: {
    resource: string;
    params: any;
    showMessage?: boolean;
    showAlert?: boolean;
    showMessageError?: boolean;
    messageText?: string;
    blockUi?: boolean;
    rejectError?: boolean;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .post(`${resource}`, params, config)
        .then((p) => {
          if (showAlert) {
            SwalService.success(messageText);
          }
          if (showMessage) {
            utils.showMessageToast(NotifType.success, messageText);
          }
          resolve(p);
        })
        .catch(({ response: { data: er } }) => {
          this.handleError(
            showMessageError,
            showAlert,
            showMessage,
            er,
            rejectError,
            reject
          );
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  private static handleError(
    showMessageError: boolean,
    showAlert: boolean,
    showMessage: boolean,
    er,
    rejectError: boolean,
    reject: (reason?: any) => void
  ) {
    if (showMessageError) {
      if (showAlert) {
        SwalService.errorAlert();
      } else if (showMessage) {
        if (er.meta.errors && er.meta.errors.length) {
          let messageText = "";
          er.meta.errors.forEach((x) =>
            x.errors.forEach((errTxt) => (messageText += "\n" + errTxt))
          );
          utils.showMessageToast(NotifType.error, messageText);
        } else {
          utils.showMessageToast(
            NotifType.error,
            t("operation_encountered_error")
          );
        }
        // this.errorHandler(er);
      }
      if (rejectError) {
        reject(er);
      }
    }
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static postToken(
    resource: string,
    params,
    getToken = false
  ): Promise<AxiosResponse> {
    let config = {};
    if (getToken) {
      config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
    }
    SwalService.blockUi();
    return new Promise<AxiosResponse>((resolve) => {
      return ApiService.vueInstance.axios
        .post(`${resource}`, params, config)
        .then((p) => {
          SwalService.success("");
          resolve(p);
        })
        .catch(() => SwalService.error(t("error_info")))
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @param showMessage
   * @param showAlert
   * @param showMessageError
   * @param messageText
   * @param blockUi
   * @param rejectError
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static put({
    resource,
    params,
    showMessage = false,
    showAlert = false,
    showMessageError = true,
    messageText = "",
    blockUi = true,
    rejectError = false,
    config = {},
  }: {
    resource: string;
    params: any;
    showMessage?: boolean;
    showAlert?: boolean;
    showMessageError?: boolean;
    messageText?: string;
    blockUi?: boolean;
    rejectError?: boolean;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .put(`${resource}`, params, config)
        .then((p) => {
          if (showAlert) {
            SwalService.success(messageText);
          } else if (showMessage) {
            utils.showMessageToast(NotifType.success, messageText);
          }
          resolve(p);
        })
        .catch(({ response: { data: er } }) => {
          this.handleError(
            showMessageError,
            showAlert,
            showMessage,
            er,
            rejectError,
            reject
          );
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(
    resource: string,
    showMessage = false,
    showAlert = false,
    showMessageError = true,
    messageText = "",
    blockUi = true,
    rejectError = false,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .delete(`${resource}`, config)
        .then((p) => {
          if (showAlert) {
            SwalService.successAlert(messageText);
          } else if (showMessage) {
            utils.showMessageToast(NotifType.success, messageText);
          }
          resolve(p);
        })
        .catch(({ response: { data: er } }) => {
          this.handleError(
            showMessageError,
            showAlert,
            showMessage,
            er,
            rejectError,
            reject
          );
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }

  public static errorHandler(error) {
    if (error.response) {
      const response = error.response;
      if (response.status === 400) {
        this.errorHandlerStatusCode(response, messages.error400);
      } else if (response.status === 401) {
        this.errorHandlerStatusCode(response, messages.error401);
        store.dispatch(Actions.LOGOUT);
        router.push({ name: "sign-in" });
      } else if (response.status === 403) {
        this.errorHandlerStatusCode(response, messages.error403);
      } else if (response.status === 404) {
        this.errorHandlerStatusCode(response, messages.error404);
      } else if (response.status === 500) {
        this.errorHandlerStatusCode(response, messages.error500);
      } else {
        SwalService.error(messages.error400);
      }
    } else {
      if (process.env.NODE_ENV === "development") {
        SwalService.error(error);
      } else {
        SwalService.error(messages.error500);
      }
    }
  }

  public static errorHandlerStatusCode(response, errorMessage) {
    if (response.data && response.data.meta) {
      const errorList = response.data.meta.errors;
      const messages = errorList.map((p) => p.errors[0]).join("\n\r");
      SwalService.error(messages);
    } else {
      SwalService.error(errorMessage);
    }
  }

  public static patch({
    resource,
    params,
    showMessage = true,
    showAlert = false,
    showMessageError = true,
    messageText = "",
    blockUi = true,
    rejectError = false,
    config = {},
  }: {
    resource: string;
    params: any;
    showMessage?: boolean;
    showAlert?: boolean;
    showMessageError?: boolean;
    messageText?: string;
    blockUi?: boolean;
    rejectError?: boolean;
    config?: AxiosRequestConfig;
  }): Promise<AxiosResponse> {
    this.setHeader();
    if (blockUi) {
      SwalService.blockUi();
    }
    return new Promise<AxiosResponse>((resolve, reject) => {
      return ApiService.vueInstance.axios
        .patch(`${resource}`, params, config)
        .then((p) => {
          if (showAlert) {
            SwalService.success(messageText);
          } else if (showMessage) {
            utils.showMessageToast(NotifType.success, messageText);
          }
          resolve(p);
        })
        .catch(({ response: { data: er } }) => {
          this.handleError(
            showMessageError,
            showAlert,
            showMessage,
            er,
            rejectError,
            reject
          );
        })
        .finally(() => {
          SwalService.blockUiHide();
        });
    });
  }
}

export default ApiService;
