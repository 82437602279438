import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { ProductType } from "@/core/models/ProductType";
import {
  getBackOfficeRoutes,
  getHotelRoutes,
  getResellersRoutes,
  getTourRoutes,
} from "@/router/backoffice-routes";
import AccountService from "@/core/services/AccountService";
export const interNationalFlightRouteBase = "/international-flight";
export const hotelRouteBase = "/hotel";
export const tourRouteBase = "/tour";
import _ from "lodash";
import { Permission } from "@/core/models/permissions/permissions";
import { array } from "yup";

declare module "vue-router" {
  interface RouteMeta {
    productType?: ProductType;
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        meta: {
          productType: ProductType.InternationalFlight,
        },
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: interNationalFlightRouteBase,
        name: "international-flight",
        meta: {
          permission: Permission["InternationalFlight"],
        },
        component: () =>
          import("@/views/international-flight/InternationalFlight.vue"),
        children: getBackOfficeRoutes(ProductType.InternationalFlight),
      },
      {
        path: "/currency",
        name: "currency",
        meta: {
          permission: Permission["FlightConfiguration:Currency:View"],
        },
        component: () =>
          import("@/views/international-flight/currency/FlightCurrency.vue"),
      },
      {
        path: "/ticketing",
        name: "ticketing",
        meta: {
          permission: Permission["Tickets:GetAll:View"],
        },
        component: () => import("@/views/ticketing/index.vue"),
      },
      {
        path: "/ticketing/detail/:ticketId",
        name: "ticketing-detail",
        meta: {
          permission: Permission["Tickets:GetAll:View"],
        },
        component: () => import("@/views/ticketing/ticket-detail.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile.vue"),
      },
      {
        path: "/resellers",
        name: "resellers",
        component: () => import("@/views/resellers/index.vue"),
        children: getResellersRoutes(),
      },
      {
        path: "/gateways",
        name: "gateways",
        meta: {
          permission: [Permission["Payment:Gateways:View"]],
        },
        component: () => import("@/views/gateways/gateways.vue"),
      },
      {
        path: "/hotel",
        name: "hotel",
        component: () => import("@/views/hotel/Index.vue"),
        children: getHotelRoutes(),
      },
      {
        path: "/tour",
        name: "tour",
        component: () => import("@/views/tour/Index.vue"),
        children: getTourRoutes(),
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/customers/index.vue"),
        meta: {
          permission: [
            Permission["Customers:List:View"],
            Permission["InternationalFlight:Customers:View"],
          ],
        },
      },
      {
        path: "/customers/detail/:userId",
        name: "customer-detail",
        meta: {
          permission: [
            Permission["Customers:List:View"],
            Permission["InternationalFlight:Customers:View"],
          ],
        },
        component: () => import("@/views/customers/customer-detail.vue"),
      },
      {
        path: "/visa/order-list",
        name: "visa",
        meta: {
          permission: [Permission["Visa:Orders:View"]],
        },
        component: () => import("@/views/visa/visa-order/VisaOrder.vue"),
      },
      {
        path: "/visa/visa-order/details/:orderId",
        name: "visa-order-detail",
        meta: {
          permission: [Permission["Visa:Orders:View"]],
        },
        component: () => import("@/views/visa/visa-order/details/index.vue"),
      },
      {
        path: "/hotel/orders/details/:orderId",
        name: "hotel-order-detail",
        meta: {
          permission: [Permission["Hotel:Orders:View"]],
        },
        component: () => import("@/views/hotel/orders/details/index.vue"),
      },
      {
        path: "/visa/visa-list",
        name: "visa-list",
        meta: {
          permission: [Permission["Visa:Countries:View"]],
        },
        component: () => import("@/views/visa/visa-list/VisaList.vue"),
      },
      {
        path: "/visa/country-list",
        name: "country-list",
        meta: {
          permission: [Permission["Visa:Countries:View"]],
        },
        component: () => import("@/views/visa/country-list/CountryList.vue"),
      },
      // {
      //   path: "/report/report-sale",
      //   name: "report-sale",
      //   component: () => import("@/views/report/report-sale/index.vue"),
      // },
      // {
      //   path: "/report/report-passenger",
      //   name: "report-passenger",
      //   component: () => import("@/views/report/report-passenger/index.vue"),
      // },
      {
        path: "/payments",
        name: "payments",
        meta: {
          permission: [
            Permission["Finance:FinancialTransaction:View"],
            Permission["InternationalFlight:TransactionsList:View"],
          ],
        },
        component: () => import("@/views/payments/index.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("@/views/setting/Index.vue"),
        children: [
          {
            path: "amenities-list",
            name: "amenities-list",
            component: () =>
              import("@/views/setting/amenities-list/AmenitiesList.vue"),
          },
          {
            path: "popular-locations-list",
            name: "popular-locations-list",
            component: () =>
              import(
                "@/views/setting/popular-locations-list/PopularLocationsList.vue"
              ),
          },
        ],
      },
      {
        path: "/user-management",
        name: "user-management",
        component: () => import("@/views/user-management/UserManagement.vue"),
        meta: {
          permission: [
            Permission["Accounts:Users:View"],
            Permission["BackOffice:Users:View"],
          ],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  if (to.path === "/sign-in") return next();

  if (_.isEmpty(store.getters.currentUser)) {
    const res = await AccountService.getCurrentUser();

    store.commit(Mutations.SET_USER, res.data.data);
    store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
  }

  if (to?.meta.permission) {
    if (Array.isArray(to?.meta.permission)) {
      const permissions = to?.meta.permission as Permission[];
      if (
        !permissions.some((x) =>
          store.getters.currentUser?.permissions?.includes(x)
        )
      ) {
        return next("/");
      }
    } else if (
      !store.getters.currentUser?.permissions?.includes(to?.meta.permission)
    ) {
      return next("/");
    }
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router;
